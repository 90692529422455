import React from 'react';
import Layout from '../components/Layout';

import { Link } from 'gatsby';

import HeroTitle from '../components/HeroTitle';

const NotFoundPage = () => (
  <Layout>
    <HeroTitle title="Oh no - page not found. 😢" />
    <div className="strip">
      <div className="row">
        <p>
          Hmm.. can't find that page. Do you need to{' '}
          <Link to="/contact">contact us</Link>?
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
